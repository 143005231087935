import React from 'react';
import { useTranslation } from "react-i18next";

// Components
import Header from '../components/Header';
import VideoBg from '../components/VideoBg';
import Divider from '../components/Divider';
// import SideImageAndText from '../components/SideImageAndText';
import Footer from '../components/Footer';

// import SideImage from '../assets/images/about-us.jpg'
import Wines1 from '../assets/images/wines1.webp'
import Wines2 from '../assets/images/wines2.webp'

const HomePage = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <Header />
            <VideoBg />
            {/* <Divider text={t("main.heading")} />
            <SideImageAndText img={SideImage} showContactBtn={false} alt={t("main.heading")} text={t("main.text")} /> */}
            <Divider text={t("main.our_products")} />
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#edebe9', padding: '60px 16px'}}>
                <img style={{width: '100%', maxWidth: '900px'}} src={Wines1} alt="wines" />
                <img style={{width: '100%', maxWidth: '900px'}} src={Wines2} alt="wines" />
            </div>
            
            <Footer />
        </>
    );

}

export default HomePage;